import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { SubscribeComponent } from './subscribe.component';
import { TopicContentModule } from 'src/@omnial/shared/topic-content/topic-content.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MaterialBaseModule,
    MaterialExtendModule,
    TopicContentModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [SubscribeComponent],
  exports: [SubscribeComponent]
})
export class SubscribeModule { }
